import { React, useRef, useState, useEffect, useLayoutEffect } from "react";
import Footer2 from "./Footer2";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";

import RegisterService from "../services/register.service";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import UserformService from "../services/userform.service";
import Select from "react-select";
import Swal from "sweetalert2";

function RegisterForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Registration Form";
  const [fields, setFields] = useState({
    sales_fname: "",
    sales_lname: "",
    store_location: "",
    fname: "",
    lname: "",
    emailid: "",
    address: "",
    model: "",
    p_address: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    question: "",
    captcha: "",
  });

  const [errors, setErrors] = useState({});
  // const [captcha, setCaptcha] = useState(false);
  const [states, setStates] = useState([]);
  const [locations, setLocations] = useState([]);

  const [signoutTime, setSignoutTime] = useState(900000);
  const [value_userform, setValue_userform] = useState([]);

  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");

  useEffect(() => {
    async function getStates() {
      let errors = {};

      try {
        const response = await UserformService.getStates();

        var states = response.data.data;
        const results = [];
        states.map((value) => {
          return results.push({
            value: value.code,
            label: value.state_name,
          });
        });
        await setStates([...results]);

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }
    getStates();

    async function getLocations() {
      let errors = {};

      try {
        const response = await RegisterService.getStoreLocation();

        var locations = response.data.data;
        const results = [];
        locations.map((value) => {
          return results.push({
            value: value.store_location,
            label: value.store_location,
          });
        });
        await setLocations([...results]);

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }
    getLocations();
  }, []);

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", true);
    localStorage.removeItem("return_page");
    localStorage.removeItem("registerForm");
    localStorage.removeItem("isRegister");
  };

  const ref = useRef();

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "state" && value === "true") {
      value = "";
    }
    if (name === "store_location" && value === "true") {
      value = "";
    }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();

    let data = {
      sales_fname: fields?.sales_fname,
      sales_lname: fields?.sales_lname,
      store_location: fields?.store_location,
      fname: fields?.fname,
      lname: fields?.lname,
      email: fields?.emailid,
      address: fields?.address,
      model: fields?.model,
      p_address: fields?.p_address,
      city: fields?.city,
      state: fields?.state,
      zip: fields?.zipcode,
      phone: fields?.phone,
      question: fields?.question,
      // captcha: captcha,
    };

    try {
      const responseuserform = RegisterService.regData(data);
      responseuserform
        .then((response) => {
          var valueUserform = response?.data?.status;
          setValue_userform(valueUserform);

          Swal.fire({
            title: "Successful!",
            text: "Form Submitted!",
            type: "success",
            icon: "success",
          }).then((result) => {
            secureLocalStorage.setItem(
              "registerform",
              JSON.stringify({
                sales_fname: fields.sales_fname,
                sales_lname: fields.sales_lname,
                store_location: fields.store_location,
                fname: fields.fname,
                lname: fields.lname,
                emailid: fields.emailid,
                address: fields.address,
                model: fields.model,
                p_address: fields.p_address,
                city: fields.city,
                state: fields.state,
                zipcode: fields.zipcode,
                phone: fields.phone,
                question: fields.question,
                // captcha: captcha,
              })
            );
            localStorage.removeItem("return_page");
            localStorage.setItem("isRegister", true);
            navigate("/register/registration-thankyou");
          });

          if (value_userform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          console.log(err);

          let errorData = {};
          errorData["sales_fname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.sales_fname &&
            err?.response?.data?.message.sales_fname[0];
          errorData["sales_lname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.sales_lname &&
            err?.response?.data?.message.sales_lname[0];
          errorData["store_location"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.store_location &&
            err?.response?.data?.message.store_location[0];
          errorData["fname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.fname &&
            err?.response?.data?.message.fname[0];
          errorData["lname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.lname &&
            err?.response?.data?.message.lname[0];
          errorData["emailid"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.email &&
            err?.response?.data?.message.email[0];
          errorData["address"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.address &&
            err?.response?.data?.message.address[0];
          errorData["model"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.model &&
            err?.response?.data?.message.model[0];
          errorData["p_address"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.p_address &&
            err?.response?.data?.message.p_address[0];
          errorData["city"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.city &&
            err?.response?.data?.message.city[0];
          errorData["state"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.state &&
            err?.response?.data?.message.state[0];
          errorData["zipcode"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.zip &&
            err?.response?.data?.message.zip[0];
          errorData["phone"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.phone &&
            err?.response?.data?.message.phone[0];
          errorData["question"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.question &&
            err?.response?.data?.message.question[0];
          errorData["checkederrorcaptcha"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.captcha &&
            err?.response?.data?.message.captcha[0];
          errorData["checkederrortwo"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.size &&
            err?.response?.data?.message.size[0];
          saveErrors(errorData);
          setTimeout(() => {
            ref?.current?.focus();
          }, 200);
        });
    } catch (err) {
      alert(err);
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      let errorData = {};
      errorData["sales_fname"] = err?.response?.data?.message.sales_fname[0];
      errorData["sales_fname"] = err?.response?.data?.message.sales_fname[0];
      errorData["store_location"] =
        err?.response?.data?.message.store_location[0];
      errorData["fname"] = err?.response?.data?.message.fname[0];
      errorData["lname"] = err?.response?.data?.message.lname[0];
      errorData["emailid"] = err?.response?.data?.message.phone[0];
      errorData["address"] = err?.response?.data?.message.address[0];
      errorData["model"] = err?.response?.data?.message.model[0];
      errorData["p_address"] = err?.response?.data?.message.p_address[0];
      errorData["city"] = err?.response?.data?.message.city[0];
      errorData["state"] = err?.response?.data?.message.state[0];
      errorData["phone"] = err?.response?.data?.message.phone[0];
      errorData["question"] = err?.response?.data?.message.question[0];
      errorData["zipcode"] = err?.response?.data?.message.zip[0];
      errorData["checkederrorcaptcha"] =
        err?.response?.data?.message.captcha[0];
      saveErrors(errorData);
    }
    // }
  };
  // const validate = (e) => {
  //   let errors = {};
  //   let isformIsValid = true;

  //   if (!captcha) {
  //     isformIsValid = false;
  //     errors["checkederrorcaptcha"] = "Captcha not verified";
  //     saveErrors(errors);
  //     return isformIsValid;
  //   }

  //   return isformIsValid;
  // };

  // function onCaptchaChange(value) {
  //   if (value === null) {
  //     setCaptcha(false);
  //   } else {
  //     setCaptcha(true);
  //   }
  // }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <HeaderLower /> */}
        <div id="main-registration-container">
          <div className="white-box form" id="font-style">
            <h1>Register for a chance to win Holiday Cash!</h1>
            <hr />
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    Sales Associate First Name{" "}
                    <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Sales Associate First Name"
                    style={
                      errors.sales_fname ? { border: "1px solid red" } : null
                    }
                    name="sales_fname"
                    // tabIndex="1"
                    id="sales_fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.sales_fname ? ref : null}
                    value={fields.sales_fname || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.sales_fname}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Sales Associate Last Name{" "}
                    <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="sales_lname"
                    style={
                      errors.sales_lname && !errors.sales_fname
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Sales Associate Last Name"
                    // tabIndex="1"
                    id="sales_lname"
                    className="searchBox_deals vc-validate"
                    value={fields.sales_lname || ""}
                    ref={errors.sales_lname && !errors.sales_fname ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.sales_lname && !errors.sales_fname
                      ? errors.sales_lname
                      : null}
                  </div>
                </div>
                <div className="clearfix"></div>

                <div className="col-md-6">
                  <label className="formsub">
                    Store Location <span className="red_point">*</span>
                  </label>

                  <Select
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select Store Location"
                    options={locations}
                    isSearchable={true}
                    isClearable={true}
                    name="store_location"
                    ref={
                      errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? ref
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["store_location"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["store_location"]: "",
                          };
                        });
                      }
                    }}
                  />
                  <div className="errorMsg">
                    {errors.store_location &&
                    !errors.sales_lname &&
                    !errors.sales_fname
                      ? errors.store_location
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    style={
                      errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="fname"
                    // tabIndex="1"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? ref
                        : null
                    }
                    value={fields.fname || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.fname &&
                    !errors.store_location &&
                    !errors.sales_lname &&
                    !errors.sales_fname
                      ? errors.fname
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Last Name"
                    // tabIndex="1"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={
                      errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname &&
                    !errors.fname &&
                    !errors.store_location &&
                    !errors.sales_lname &&
                    !errors.sales_fname
                      ? errors.lname
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    // tabIndex="10"
                    ref={
                      errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="E-mail"
                    value={fields.emailid || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid &&
                    !errors.lname &&
                    !errors.fname &&
                    !errors.store_location &&
                    !errors.sales_lname &&
                    !errors.sales_fname
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                {/* <div className="col-md-6">
                  <label className="formsub">
                    Customer Birthday <span className="red_point">*</span>
                  </label>
                  <input
                    type="date"
                    name="dob"
                    // tabIndex="10"
                    ref={
                      errors.dob &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? ref
                        : null
                    }
                    style={
                      errors.dob &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    id="dob"
                    placeholder="Customer Birthday"
                    value={fields.dob || ""}
                    onChange={handleChange}
                    onKeyUp={(e) => e.preventDefault()}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                  <div className="errorMsg">
                    {errors.dob &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname &&
                    !errors.store_location &&
                    !errors.sales_lname &&
                    !errors.sales_fname
                      ? errors.dob
                      : null}
                  </div>
                </div> */}

                <div className="col-md-6">
                  <label required className="formsub">
                    Customer Address <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    style={
                      errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Address"
                    // tabIndex="1"
                    id="address"
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.address || ""}
                    ref={
                      errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname &&
                    !errors.store_location &&
                    !errors.sales_lname &&
                    !errors.sales_fname
                      ? errors.address
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Apartment/Suite # (Optional)
                    <span className="red_point"></span>
                  </label>
                  <input
                    type="text"
                    name="p_address"
                    id="p_address"
                    placeholder="Apartment/Suite #"
                    // tabindex="4"
                    autoFocus=""
                    value={fields.p_address || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    City <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="city"
                    style={
                      errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? { border: "1px solid red" }
                        : null
                    }
                    // tabIndex="4"
                    placeholder="City"
                    id="city"
                    value={fields.city || ""}
                    ref={
                      errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname &&
                    !errors.store_location &&
                    !errors.sales_lname &&
                    !errors.sales_fname
                      ? errors.city
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    State <span className="red_point">*</span>
                  </label>

                  <Select
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select State"
                    options={states}
                    isSearchable={true}
                    isClearable={true}
                    name="state"
                    ref={
                      errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? ref
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["state"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["state"]: "",
                          };
                        });
                      }
                    }}
                  />
                  <div className="errorMsg">
                    {errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname &&
                    !errors.store_location &&
                    !errors.sales_lname &&
                    !errors.sales_fname
                      ? errors.state
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Zip Code <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="zipcode"
                    style={
                      errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="zipcode"
                    maxLength="10"
                    // tabIndex="6"
                    placeholder="Zip Code"
                    value={fields.zipcode || ""}
                    ref={
                      errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {" "}
                    {errors.zipcode &&
                    !errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname &&
                    !errors.store_location &&
                    !errors.sales_lname &&
                    !errors.sales_fname
                      ? errors.zipcode
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone# <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    style={
                      errors.phone &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="phone"
                    placeholder="Phone"
                    value={fields.phone || ""}
                    ref={
                      errors.phone &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {" "}
                    {errors.phone &&
                    !errors.zipcode &&
                    !errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname &&
                    !errors.store_location &&
                    !errors.sales_lname &&
                    !errors.sales_fname
                      ? errors.phone
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Which new Purple model did you like the best?{" "}
                    <span className="red_point">*</span>
                  </label>
                  <select
                    style={
                      errors.model &&
                      !errors.phone &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.model &&
                      !errors.phone &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? ref
                        : null
                    }
                    name="model"
                    id="model"
                    // tabIndex="5"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value>Select Model</option>
                    <option value="Restore Firm">Restore Firm</option>
                    <option value="Restore Plus Firm">Restore Plus Firm</option>
                    <option value="Restore Plus Soft">Restore Plus Soft</option>
                    <option value="Restore Premier Firm">
                      Restore Premier Firm
                    </option>
                    <option value="Restore Soft">Restore Soft</option>
                  </select>
                  <div className="errorMsg">
                    {errors.model &&
                    !errors.phone &&
                    !errors.zipcode &&
                    !errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname &&
                    !errors.store_location &&
                    !errors.sales_lname &&
                    !errors.sales_fname
                      ? errors.model
                      : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="formsub">
                    Did you purchase a mattress today?
                    <span className="red_point">*</span>
                  </label>
                  <label className="inline formsub">
                    <input
                      type="radio"
                      id="question"
                      name="question"
                      value="Yes"
                      onChange={handleChange}
                    />{" "}
                    Yes
                  </label>
                  <label className="inline ms-2 formsub">
                    <input
                      type="radio"
                      id="question"
                      name="question"
                      value="No"
                      onChange={handleChange}
                    />{" "}
                    No
                  </label>
                  <label className="inline ms-2 formsub">
                    <input
                      type="radio"
                      id="question"
                      name="question"
                      value="Undecided"
                      onChange={handleChange}
                      ref={
                        errors.question &&
                        !errors.phone &&
                        !errors.zipcode &&
                        !errors.state &&
                        !errors.city &&
                        !errors.address &&
                        !errors.model &&
                        !errors.emailid &&
                        !errors.lname &&
                        !errors.fname &&
                        !errors.store_location &&
                        !errors.sales_lname &&
                        !errors.sales_fname
                          ? ref
                          : null
                      }
                    />{" "}
                    Undecided
                  </label>

                  <label className="inline ms-2 formsub">
                    <div className="errorMsg d-inline">
                      {" "}
                      {errors.question &&
                      !errors.phone &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.model &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname &&
                      !errors.store_location &&
                      !errors.sales_lname &&
                      !errors.sales_fname
                        ? errors.question
                        : null}
                    </div>
                  </label>
                  <hr />
                </div>

                {/* <br />
                <div className="overflow-hider">
                  <Recaptcha
                    sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                    onChange={onCaptchaChange}
                  />
                </div> */}
                {/* <div className="errorMsg">{errors.checkederrorcaptcha}</div> */}
                {/* <div className="errorMsg">
                  {" "}
                  {!errors.question &&
                  !errors.phone &&
                  !errors.zipcode &&
                  !errors.state &&
                  !errors.city &&
                  !errors.address &&
                  !errors.model &&
                  !errors.emailid &&
                  !errors.lname &&
                  !errors.fname &&
                  !errors.store_location &&
                  !errors.sales_lname &&
                  !errors.sales_fname
                    ? errors.checkederrorcaptcha
                    : null}
                </div> */}
                <br />
                <div className="clearfix"></div>
              </div>

              <div className="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn2 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default RegisterForm;
