import React from "react";
import miskelllyMark from "../Images/miskelly_mark.png";

function ContactUs() {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src={miskelllyMark} alt="miskelly_mark.png" />
      </div>

      <div className="col-md-11" style={{ fontFamily: "Arial" }}>
        If you have any questions, for the fastest service contact us on our
        live chat, in the lower right hand corner Or contact us via email at{" "}
        <a href="mailto:customercare@eliterewards.biz">
          {process.env.REACT_APP_DESC_CUSTOMERCARE}
        </a>
        <br />
        Or call us at{" "}
        <a href="tel:1-866-ELITE-21">{process.env.REACT_APP_DESC_CONTACTUS}</a>
        <br />
        Our business hours are Monday - Friday, 8:00am - 7:00pm EST.
      </div>
    </div>
  );
}
export default ContactUs;
